.Services {
	flex-direction: column;
	text-align: center;
	max-width: 1500px;
	margin: 0 auto;
	padding: 50px 0;
	background-color: var(--brand-main-ten)
}

.service-filter {
	border-bottom: 2px solid var(--brand-main-full);
	padding-bottom: 10px
}

.serv-filt-item {
	font-size: 14px;
	padding: 10px 20px;
	border: 2px solid var(--brand-main-full);
	margin: 0 15px;
	border-radius: 10px;
	cursor: pointer;
}

.serv-filt-item.active {
	background-color: var(--brand-main-full);
	color: white;
}

.Services .service-top {
	max-width: 800px;
	margin: 0 30px;
}

.Services .service-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;
	align-items: stretch

}
.Services .service-item {
	flex-basis: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 10px;
	background-image: url(../img/img-1.jpeg);
	background-size: cover;
	margin: 10px 5%;
	position: relative;
	z-index: 1;
	overflow: hidden;
  box-sizing: border-box;
}
.Services .service-item::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(60deg, #002d59  0%, #1368E9 100%);;
	opacity: .9;
	z-index: -1;
}
.Services .service-bottom .icon {
	height: 80px;
	width: 80px;
	margin-bottom: 20px;
}

.Services .service-item h2 {
	font-size: 2rem;
	color: white;
	margin-bottom: 10px;
	text-transform: uppercase;
}


.Services .service-item p {
	color: white;
	text-align: left;
}






@media only screen and (min-width: 768px) {

	.Services .service-bottom .service-item {
		flex-basis: 45%;
		margin: 2.5%;
	}


}
/* End Media Query For Tablet */

/* Media Query For Desktop */
@media only screen and (min-width: 1200px) {

	.Services .service-bottom .service-item {
		flex-basis: 22%;
		margin: 1.5%;
	}
}
/* End  Media Query For Desktop */
