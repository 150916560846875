.Home {
  background-image: url(../img/home.jpg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  z-index: 1;
  min-height: 80vh;
  max-width: 100vw;
  justify-content: center;
}

.Home::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: .7;
  z-index: -1;
}

.Home .inner-home {
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
  width: 95vw;
}

.Home .intro {
  background-color: var(--brand-main-twf);
  padding: 20px;
  border-radius: 10px;
  margin: auto 20px;
  width: 40%;
}

.Home .subscribe {
  background-color:rgba(0, 45, 89, 0.7);
  padding: 20px;
  border-radius: 10px;
  margin: auto 20px;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.Home .subscribe h3{
  color: white;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 1.5
}

.Home .subscribe form{
  width: 90%
}

.Home .subscribe input{
  position: relative;
  box-sizing: border-box;
  z-index: 17;
  height: 35px;
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.70);
  border-radius: 20px;
  font-size: 14px;
  color: white;
  padding: 0px 10px;
  transition: border-color 200ms ease, box-shadow 200ms ease;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  text-align: left;
  background-position: initial initial;
  background-repeat: initial initial;
}

.Home .subscribe input:focus{
  background-color: white;
  color: black;
}

.Home .home-bttns2 {
  display: none
}

.Home h1 {
  display: block;
  width: fit-content;
  font-size: 30px;
  position: relative;
  color: transparent;
  animation: text_reveal .5s ease forwards;
  animation-delay: 1s;
}

.Home h1:nth-child(1) {
  animation-delay: 1s;
}
.Home h1:nth-child(2) {
  animation-delay: 2s;
}
.Home h1:nth-child(3) {
  animation: text_reveal_name .5s ease forwards;
  animation-delay: 3s;
}
.Home h1 span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: var(--brand-main-full);
  animation: text_reveal_box 1s ease;
  animation-delay: .5s;
}
.Home h1:nth-child(1) span {
  animation-delay: .5s;
}
.Home h1:nth-child(2) span {
  animation-delay: 1.5s;
}
.Home h1:nth-child(3) span {
  animation-delay: 2.5s;
}

.Home .home-bttns {
	display: inline-block;
	justify-content: center;
}

.cta.external {
  display: flex;
  justify-content:space-around;;
  align-items:center;
  max-width: 60%;
  margin: auto;
}


@keyframes text_reveal_box {
50% {
width: 100%;
left: 0;
	}
100% {
width: 0;
left: 100%;
	}
}
@keyframes text_reveal {
100% {
color: white;
	}
}
@keyframes text_reveal_name {
100% {
color: #1367E9;
font-weight: 500;
	}
}


@media only screen and (max-width: 700px) {

  .Home .inner-home {
    flex-direction: column;
    margin-top: 100px;
  }

  .Home .inner-home .intro{
    width: 85%;
    max-width: 400px
  }

  .Home .inner-home .subscribe{
    margin: 20px auto;
    width: 85%;
    max-width: 400px
  }

/* End header */
}
